<template>
  <div class="AppOrderingStatusBar">
    <div class="AppOrderingStatusBar__inner">
      <ul class="AppOrderingStatusBar__items">
        <li
          class="AppOrderingStatusBar__item"
          v-for="(step, key) in getPageSteps"
          :key="key"
          :class="{
            active: key === getPageActiveStep,
            done: getPageDisabledSteps.includes(key),
          }"
        >
          {{ step['title_' + selectedLang] }}
        </li>
      </ul>
      <ul class="AppOrderingStatusBar__lines">
        <li
          class="AppOrderingStatusBar__line"
          v-for="(step, key) in getPageSteps"
          :key="key"
          :class="{
            active: key === getPageActiveStep,
            done: getPageDisabledSteps.includes(key),
          }"
        ></li>
      </ul>
      <span class="AppOrderingStatusBar__steps">
        <app-title class="AppOrderingStatusBar__step title--small title--W300 title--montserrat"
          >{{ getPageSteps[getPageActiveStep].sort + 1 }}/<!-- {{
            Object.keys(getPageActiveStep).length
          }} -->5</app-title
        >

        {{ getPageSteps[getPageActiveStep]['title_' + selectedLang] }}</span
      >
    </div>
  </div>
</template>

<script>
  import AppTitle                  from '../../../components/AppTitle.vue'
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('ordering');
export default {
  name: '',
  components: {
    AppTitle
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['changeActiveStep']),
  },
  computed: {
    ...mapGetters([
      'getPage',
      'getPageSteps',
      'getPageActiveStep',
      'getPageDisabledSteps',
    ]),
    ...mapRootGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppOrderingStatusBar {
  &__items {
    @include flex(center, center, row, nowrap);

    @include media-d-m($screen-desktop-large) {
      @include flex(space-between, center, row, nowrap);
    }
  }

  &__item {
    @include text($h22, 300, $light-yellow, $f-montserrat);

    &:not(:last-of-type) {
      margin-right: 40px;

      @include media-d-m($screen-desktop-large) {
        margin-right: 0;
      }
    }

    &.active {
      color: $yellow;
    }

    &.done {
      color: $grey;
    }

    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__lines {
    display: none;
    @include media-d-m($screen-netbook) {
      display: block;
      width: calc(100% + 60px);
      margin-left: -30px;
      @include flex(space-between, center, row, nowrap);
      margin-bottom: 20px;
    }
    // @include media-d-m($screen-iphone-6) {
    //   width: calc(100% + 6%);
    //   margin-left: -3%;
    // }
  }

  &__line {
    width: 20%;
    height: 6px;
    background-color: $light-yellow;

    &.active {
      background-color: $yellow;
    }

    &.done {
      background-color: $grey;
    }
  }

  &__steps {
    display: none;
    @include media-d-m($screen-netbook) {
      @include text($h22, 300, $grey, $f-montserrat);
      display: flex;
    }
  }

  &__step {
    color: $light-yellow;
    margin-right: 15px;
  }

  &__amount {
    color: $light-yellow;
    margin-right: 15px;
  }
}
</style>
