<template>
  <div :id="id" class="AppRadio">
    <div
      v-for="(option, index) in options"
      :key="option.text"
      class="AppRadio--margin-bottom"
    >
      <input
        class="AppRadio__radio"
        :id="id + index"
        :name="id"
        type="radio"
        v-model="radioValue"
        :value="option.value"
        @input="pushvModel(option.key, !!radioValue)"
        :checked="option.value === valueInput"
        :class="inputClass"
        :disabled="disabled"
        :required="required"
        @change="updateValue(radioValue)"
      />
      <label :for="id + index">{{ option.text }}</label>
      <slot v-if="option.value === radioValue && option.value === true"/>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions as mapRootActions } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');
export default {
  model: {
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: '',
    },
  },

  data() {
    return {
      radioValue: this.value,
      valueInput: ''
    };
  },

  mounted() {
    this.valueInput = this.getvData('later');
    for (let i = 0; i < document.getElementsByClassName('AppRadio__radio').length; i++) {
      if (!!document.getElementsByClassName('AppRadio__radio')[i].value === this.valueInput) {
        document.getElementsByClassName('AppRadio__radio')[i].checked = true;
        this.radioValue = !!document.getElementsByClassName('AppRadio__radio')[i].value
      }
    }
  },

  methods: {
    ...mapActions(['changeData']),
    ...mapGetters(['getData']),
    pushvModel(key, value) {
      this.changeData({ key: key, value: value });
    },
    updateValue(value) {
      this.$emit('change', value);
    },
    getvData(state) {
      return this.getData()[state];
    },
  },
};
</script>

<style lang="scss">
.AppRadio {
  &--margin-bottom {
    &:not(:last-of-type) {
      margin-bottom: 22px;
    }
  }
  &__radio {
    position: absolute;
    z-index: -1;
    opacity: 0;

    /* для элемента label связанного с .AppRadio__radio */
    & + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      @include text($h16, 300, $grey, $f-montserrat);
      cursor: pointer;
    }

    &:checked + label {
      @include text($h16, 300, $light-yellow, $f-montserrat);
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    & + label::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 50%;
      margin-right: 13px;
      background-position: center;
      background-repeat: no-repeat;
    }

    /* стили при наведении курсора на радио */
    // &:not(:disabled):not(:checked) + label:hover::before {
    // }

    /* стили для активной радиокнопки (при нажатии на неё) */
    // &:not(:disabled):active + label::before {
    // }

    /* стили для радиокнопки, находящейся в фокусе */
    // &:focus + label::before {
    // }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    // &:focus:not(:checked) + label::before {
    // }

    /* стили для радиокнопки, находящейся в состоянии checked */
    &:checked + label::before {
      background-image: url('../../../assets/images/checked/checked__radio.svg');
    }

    /* стили для радиокнопки, находящейся в состоянии disabled */
    &:disabled + label::before {
      background-color: $grey;
    }
  }
}
</style>
