<template>
  <div class="AppOrdering">
    <div class="AppOrdering__inner container">
      <app-ordering-status-bar class="AppOrdering__status-bar" />
      <component
        v-bind:is="getPageSteps[getPageActiveStep].component"
      ></component>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import {
  AppOrderingAudit,
  AppOrderingCashier,
  AppOrderingStatusBar,
  AppOrderingPay,
  AppOrderingDone,
}                    from "../components";
import { AppHeader } from "@/modules/app/components";

const { mapGetters } = createNamespacedHelpers("ordering");

export default {
  name: "AppOrdering",
  components: {
    AppHeader,
    AppOrderingStatusBar,
    AppOrderingCashier,
    AppOrderingAudit,
    AppOrderingPay,
    AppOrderingDone,
  },

  computed: {
    ...mapGetters(["getPageSteps", "getPageActiveStep"]),
  },
};
</script>

<style lang="scss">
.AppOrdering {
  background: url("../../../../../assets/images/ordering/ordering__bg.png") center
    no-repeat;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;

  margin-top: -115px;

  @include media-d-m($screen-netbook) {
    background: none;
    margin-top: -105px;
  }

  &::after {
    content: "";
    position: absolute;
    @include position();
    background: $body-color;
    opacity: 0.95;
    z-index: 2;
  }

  &__inner {
    position: relative;
    z-index: 3;
    padding-top: 196px;
    @include media-d-m($screen-netbook) {
      padding-top: 105px;
    }
  }

  &__header {
    margin-bottom: 70px;
  }

  &__status-bar {
    margin-bottom: 100px;
    @include media-d-m($screen-netbook) {
      margin-bottom: 85px;
    }
  }
}
</style>
