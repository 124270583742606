<template>
  <div class="AppOrderingDone">
    <div class="AppOrderingDone__inner container">
      <app-title class="AppOrderingDone__title">
        {{text1['text_' + selectedLang] + getData.address + ", "}}
        {{ getData.later ? getData.date + text2['text_' + selectedLang] + getData.time + "." : text3['text_' + selectedLang] }}
      </app-title>
      <h1 class="AppOrderingDone__404">{{text4['text_' + selectedLang]}}:)</h1>

      <div v-if="$route.params.type === 'item'"
          @click="
        [scrollToTop(), logOrdering(), resetState(), resetStateItem()]
      "
      >
      <router-link tag="button"
                   to="/"
      >
        <app-button class="button--fill">{{text5['text_' + selectedLang]}}</app-button>
      </router-link>
      </div>
      <div v-if="$route.params.type === 'pie'"
          @click="
        [scrollToTop(), logOrdering(), resetState(), resetStatePie()]
      "
      >
        <router-link tag="button"
                     to="/"
        >
          <app-button class="button--fill">{{text5['text_' + selectedLang]}}</app-button>
        </router-link>
      </div>
      <div v-if="$route.params.type === 'all'"
          @click="
        [scrollToTop(), logOrdering(), resetState(), resetStateItem(), resetStatePie()]
      "
      >
        <router-link tag="button"
                     to="/"
        >
          <app-button class="button--fill">{{text5['text_' + selectedLang]}}</app-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import { createNamespacedHelpers, mapGetters as mapRootGetters } from "vuex";
import {mapActions, mapGetters} from "vuex";

import {AppButton} from "@/modules/app/components";
import {AppTitle}  from '@/modules/app/components';

export default {
  name: "AppOrderingAudit.vue",
  components: {AppButton, AppTitle},
  data() {
    return {
      text1: {
        text_ru: 'Ваш заказ принят и будет доставлен на адрес ',
        text_ua: 'Ваше замовлення прийняте і буде доставлений на адресу ',
        text_pl: 'Twoje zamówienie zostało przyjęte i zostanie dostarczone na adres ',
        text_en: 'Your order has been accepted and will be delivered to the address ',
      },
      text2: {
        text_ru: ' в ',
        text_ua: ' о ',
        text_pl: ' o ',
        text_en: ' at ',
      },
      text3: {
        text_ru: 'через 60мин.',
        text_ua: 'через 60хв.',
        text_pl: 'w 60 minut.',
        text_en: 'in 60 minutes.',
      },
      text4: {
        text_ru: 'Приятного апетита',
        text_ua: 'Смачного',
        text_pl: 'Smacznego',
        text_en: 'Bon Appetit',
      },
      text5: {
        text_ru: 'Перейти на главную',
        text_ua: 'Перейти на головну',
        text_pl: 'Przejdź do strony głównej',
        text_en: 'Go to Main page',
      }
    };
  },
  computed: {
    ...mapGetters("ordering", ["getInfo", 'getData']),
    ...mapGetters(["getCartItems", 'getCartTotalPrice']),
    ...mapGetters("lang", ["selectedLang"])
  },
  methods: {
    ...mapActions("ordering", ["changeData", "changeActiveStep", "resetState", "resetStateItem", "resetStatePie"]),

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    resetState() {
      this.resetState()
    },

    resetStateItem() {
      this.resetStateItem()
    },

    resetStatePie() {
      this.resetStatePie()
    },

    logOrdering() {
      let items = {};
      let delivery_date = null;

      for (const [key, value] of Object.entries(this.getCartItems)) {
        items[key] = {
          title: value.title,
          quantity: value.amount,
        };
      }

      if (this.getInfo.delivery_date !== undefined) {
        delivery_date = `${this.getInfo.delivery_date.date} ${this.getInfo.delivery_date.time}`
      }

      const info = {
        order_data: {...this.getInfo, delivery_date},
        items,
        XDEBUG_SESSION_START: 'PHPSTORM'
      };
    },
  },
};
</script>

<style lang="scss">
.AppOrderingDone {

  &__inner {
    @include flex(flex-start, flex-start, column, nowrap);
    position: relative;
    z-index: 3;
  }

  &__404 {
    @include text($h31, 500, $light-yellow, $f-montserrat);
    margin-bottom: 118px;
  }

  &__title {
    @include text($h18, 500, $grey, $f-montserrat);
  }
}
</style>
