<template>
  <div class="AppOrderingCashier">
    <div class="AppOrderingCashier__inner">
      <form class=".AppOrderingCashier__form" action="" v-on:submit.stop>
        <ul class="AppOrderingCashier__items">
          <li class="AppOrderingCashier__item">
            <app-title
                class="AppOrderingCashier__title title--small title--W500 title--montserrat title--yellow"
            >{{text1['text_' + selectedLang]}}
            </app-title>
            <app-input
                class="AppOrderingCashier__input"
                :type="'text'"
                :placeholder="text2['text_' + selectedLang]"
                :nameInput="'name'"
                :valid="nameValid"
                :hasError="nameHasError"
                @blur="nameHasError = !nameValid"
                @focus="nameHasError = false"
            />
            <app-input
                class="AppOrderingCashier__input"
                :type="'tel'"
                :placeholder="text3['text_' + selectedLang]"
                :nameInput="'phone'"
                :valid="phoneValid"
                :hasError="phoneHasError"
                @blur="phoneHasError = !phoneValid"
                @focus="phoneHasError = false"
                v-mask="'+38 (0##) ##-##-###'"
            />
            <app-input
                class="AppOrderingCashier__input"
                :type="'email'"
                :placeholder="'Email'"
                :nameInput="'email'"
            />
          </li>
          <li class="AppOrderingCashier__item">
            <app-title
                class="AppOrderingCashier__title title--small title--W500 title--montserrat title--yellow"
            >{{ text4['text_' + selectedLang] }}
            </app-title>
            <app-input
                class="AppOrderingCashier__input"
                :type="'text'"
                :placeholder="text5['text_' + selectedLang]"
                :nameInput="'address'"
                :disabled="!!getData.pickup"
            />
            <app-input
                class="AppOrderingCashier__input"
                :type="'text'"
                :placeholder="text6['text_' + selectedLang]"
                :nameInput="'comment'"
                :disabled="!!getData.pickup"
            />
            <app-checkbox
                :nameInput="'pickup'"
                :value="'pickup'"
                :label="text7['text_' + selectedLang]"
            />
          </li>
          <li class="AppOrderingCashier__item">
            <app-title
                class="AppOrderingCashier__title title--small title--W500 title--montserrat title--yellow"
            >{{text8['text_' + selectedLang]}}
            </app-title>
            <app-radio
                class="AppOrderingCashier__radioButton"
                id="orderingRadioButton"
                v-model="fields.delivery"
                :options="delivery__radio"
                :required="true"
                :inputClass="'radio__button'"
                @change="changeDeliveryType"
            >
              <template class="picker">
                <div class="picker">
                  <app-datepicker class="picker__date"/>
                  <app-timepicker class="picker__time"/>
                </div>
              </template>
            </app-radio>
          </li>
        </ul>
        <div class="AppOrderingCashier__agree">
          <app-checkbox
              :nameInput="'agree'"
              :value="'agree'"
              :label="text9['text_' + selectedLang]"
              :getClass="'light-yellow'"
          />
          <a href="#">{{ text10['text_' + selectedLang] }}</a>
        </div>
        <button
            class="AppOrderingCashier__button"
            :class="{ disabled: disabled }"
            :disabled="disabled"
            type="submit"
            @click.prevent="
            disabled
              ? ''
              : [changeActiveStep({ key: 'isAudit' }), scrollToTop()]
          "
        >
          <app-button class="button--fill">{{text11['text_' + selectedLang]}}</app-button>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapActions as mapRootActions, mapGetters as mapRootGetters } from 'vuex';

import {
  AppButton,
  AppCheckbox,
  AppDatepicker,
  AppInput,
  AppRadio,
  AppTimepicker,
  AppTitle,
} from '@/modules/app/components';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');

export default {
  name      : 'AppOrderingCashier',
  components: {
    AppTitle,
    AppInput,
    AppCheckbox,
    AppRadio,
    AppButton,
    AppDatepicker,
    AppTimepicker,
  },

  data() {
    return {
      fields         : {
        delivery             : null,
        yes_no_other_question: '',
      },
      delivery__radio: [
        {
          key  : 'delivery_asap',
          value: false,
          text : 'Прямо сейчас',
        },
        {
          key  : 'delivery_asap',
          value: true,
          text : 'В назначенное время',
        },
      ],
      nameHasError   : false,
      phoneHasError  : false,
      deliveryType   : false,
      text1: {
        text_ru: 'Контактные данные',
        text_ua: 'Контактні дані',
        text_pl: 'Szczegóły kontaktu',
        text_en: 'Contact details',
      },
      text2: {
        text_ru: 'Имя',
        text_ua: 'Ім\'я',
        text_pl: 'Imię',
        text_en: 'Name',
      },
      text3: {
        text_ru: 'Телефон',
        text_ua: 'Телефон',
        text_pl: 'Telefon',
        text_en: 'Phone number',
      },
      text4: {
        text_ru: 'Адрес доставки',
        text_ua: 'Адреса доставки',
        text_pl: 'Adres dostawy',
        text_en: 'Delivery address',
      },
      text5: {
        text_ru: 'Адрес',
        text_ua: 'Адреса',
        text_pl: 'Adres',
        text_en: 'Address',
      },
      text6: {
        text_ru: 'Коментарий: этаж, подъезд...',
        text_ua: 'Коментар: поверх, під\'їзд ...',
        text_pl: 'Komentarz: podłoga, wejście...',
        text_en: 'Commentary: floor, entrance ...',
      },
      text7: {
        text_ru: 'Заберу сам из Тарты',
        text_ua: 'Заберу сам з Тарти',
        text_pl: 'Odbiorę to z Tarta',
        text_en: 'I will pick it up from Tarta',
      },
      text8: {
        text_ru: 'Время доставки',
        text_ua: 'Час доставки',
        text_pl: 'Czas dostawy',
        text_en: 'Time of delivery',
      },
      text9: {
        text_ru: 'Соглашаюсь с',
        text_ua: 'Погоджуюся з',
        text_pl: 'Zgadzam się z',
        text_en: 'I agree with',
      },
      text10: {
        text_ru: 'правилами',
        text_ua: 'правилами',
        text_pl: 'zasady',
        text_en: 'rules',
      },
      text11: {
        text_ru: 'Проверить заказ',
        text_ua: 'Оформити замовлення',
        text_pl: 'Sprawdź zamówienie',
        text_en: 'Check order',
      },
    };
  },

  mounted() {
    if (this.getData.later){
      this.deliveryType = true
    }
  },

  methods: {
    ...mapActions([ 'changeData', 'changeActiveStep' ]),
    ...mapRootActions([ 'changeAllPrice', 'changeItemsInCart' ]),

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    changeDeliveryType(data) {
      this.changeData({ key: 'later', value: data });
      this.deliveryType = true
    }
  },

  computed: {
    ...mapGetters([ 'getData', 'selectedLang' ]),
    ...mapRootGetters('lang', ['selectedLang']),

    nameValid() {
      return this.getData.name.length >= 3;
    },

    phoneValid() {
      return this.getData.phone.length === 19;
    },

    disabled() {
      return !this.nameValid || !this.phoneValid || !this.getData.agree || !this.deliveryType;
    },
  },
};
</script>

<style lang="scss">
.AppOrderingCashier {
  @include media-d-m($screen-netbook) {
    padding-bottom: 84px;
  }

  &__items {
    @include flex(space-between, flex-start, row, nowrap);

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, nowrap);
    }
  }

  &__item {
    min-width: 295px;

    @include media-d-m($screen-desktop-large) {
      min-width: calc(280px - 30px);

      &:not(:last-of-type) {
        margin-right: 30px;
      }

      @include media-d-m($screen-netbook) {
        margin-bottom: 107px;
      }
    }
  }

  &__title {
    margin-bottom: 56px;
    white-space: nowrap;
    @include media-d-m($screen-netbook) {
      margin-bottom: 46px;
    }
  }

  &__input {
    margin-bottom: 33px;
    @include media-d-m($screen-netbook) {
      margin-bottom: 15px;
    }
  }

  // &__radioButton {

  // }
  &__agree {
    @include flex(flex-start, flex-start, row, nowrap);
    margin-bottom: 13px;
    margin-top: 50px;

    & a {
      @include text($h16, 300, $grey, $f-montserrat);
      text-decoration: underline;
      margin-left: 5px;
    }

    @include media-d-m($screen-netbook) {
      margin-top: 0;
    }
  }

  .picker {
    margin-top: 10px;
    @include flex(center, center, row, nowrap);

    &__date {
      position: relative;
      margin-bottom: 0px;
      max-width: 185px;
      width: 100%;
      margin-right: 10px;

      @include media-d-m($screen-desktop-large) {
        max-width: 155px;
      }
    }

    &__time {
      position: relative;
      max-width: 100px;
      width: 100%;

      @include media-d-m($screen-desktop-large) {
        max-width: 80px;
      }
    }
  }

  &__button {
    position: relative;

    &.disabled {
      opacity: 0.5;
      cursor: none;

      &::after {
        @include after();
      }
    }
  }
}
</style>
