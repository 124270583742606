<template>
  <div>
    <date-picker
      v-model="date"
      @input="pushvModel('date', date)"
      :default-value="new Date().setHours(12, 0, 0, 0)"
      :disabled-date="notBeforeToday"
      valueType="format"
      value-type="DD/MMMM/YYYY"
      format="YYYY-MM-DD"
      default-panel="mounthLabel"
      :lang="lang"
      prefix-class="vue"
      :append-to-body="false"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/locale/ru';
import { createNamespacedHelpers, mapActions as mapRootActions } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');

export default {
  components: { DatePicker },
  data() {
    return {
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    };
  },

  mounted() {
    this.date = this.getvData('date')
  },

  methods: {
    ...mapActions(['changeData']),
    ...mapGetters(['getData']),

    pushvModel(key, value) {
      this.changeData({ key, value });
    },
    getvData(state) {
      return this.getData()[state];
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>

<style lang="scss">
$namespace: 'vue'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />
@import '../../../scss/datepicker/index';
</style>
