<template>
  <date-picker
    v-model="value"
    @input="pushvModel('time', value)"
    value-type="format"
    format="HH:mm"
    type="time"
    :default-value="new Date().setHours(10, 0, 0, 0)"
    :disabled-time="notBeforeTenOClock"
    :show-second="false"
    :minute-step="15"
    prefix-class="vue"
  ></date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/locale/ru';
import { createNamespacedHelpers, mapActions as mapRootActions } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');

export default {
  components: { DatePicker },
  data() {
    return {
      value: '',
    };
  },

  mounted() {
    this.value = this.getvData('time')
  },

  methods: {
    ...mapActions(['changeData']),
    ...mapGetters(['getData']),
    getvData(state) {
      return this.getData()[state];
    },
    pushvModel(key, value) {
      this.changeData({ key: key, value: value });
    },
    notBeforeTenOClock(date) {
      return date.getHours() < 10 || date.getHours() > 18;
    },
    notAfterTenOClock(date) {
      return date.getHours() > 18;
    },
  },
};
</script>
<style lang="scss">
$namespace: 'vue'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />
@import '../../../scss/datepicker/index';
</style>
