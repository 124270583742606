<template>
  <div class="AppOrderingPay">
    <div class="AppOrderingPay__buttons">
      <button
          @click="paySuccess()"
          class="AppOrderingPay__button"
      >
        <app-button class="button--fill">{{ text1['text_' + selectedLang] }}</app-button>
      </button>
      <div
          class="AppOrderingPay__backState"
          @click="[changeActiveStep({ key: 'isAudit' }), scrollToTop()]"
      >
        <icon-arrow class="iconArrow--left iconArrow--light-yellow"/>
        <span class="AppOrderingPay__backState-text">{{ text2['text_' + selectedLang] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { createNamespacedHelpers, mapGetters as mapRootGetters } from "vuex";
import {mapActions, mapGetters} from "vuex";
import iconArrow                from "@/modules/app/components/svg/iconArrow.vue";

import {AppButton}    from "@/modules/app/components";
import {API_BASE_URL}   from '@/constants';
import { http, router } from "@/modules/http/router";

export default {
  name: "AppOrderingPay.vue",
  components: {AppButton, iconArrow},
  data() {
    return {
      text1: {
        text_ru: 'Оплатил',
        text_ua: 'Сплатив',
        text_pl: 'Płatny',
        text_en: 'Paid',
      },
      text2: {
        text_ru: 'Вернуться',
        text_ua: 'Повернутися',
        text_pl: 'Powrót',
        text_en: 'Return',
      },
    };
  },
  computed: {
    ...mapGetters("ordering", ["getInfo"]),
    ...mapGetters(["getCartItems", 'getCartTotalPrice']),
    ...mapGetters('lang', ['selectedLang']),
  },
  methods: {
    ...mapActions("ordering", ["changeData", "changeActiveStep", 'createOrder']),
    ...mapActions(['clearCart']),

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    paySuccess() {
      this.logOrdering();
      this.createOrder();
      this.clearCart();
      // this.changeActiveStep({ key: 'idDone' });
      this.scrollToTop();
      // location.href = '/'
    },

    logOrdering() {
      let items = {};
      let delivery_date = null;

      for (const [key, value] of Object.entries(this.getCartItems)) {
        items[key] = {
          title: value.title,
          quantity: value.amount,
        };
      }

      if (this.getInfo.delivery_date !== undefined) {
        delivery_date = `${this.getInfo.delivery_date.date} ${this.getInfo.delivery_date.time}`
      }

      const info = {
        order_data: {...this.getInfo, delivery_date},
        items,
        XDEBUG_SESSION_START: 'PHPSTORM'
      };
    },
  },
};
</script>

<style lang="scss">
.AppOrderingPay__buttons {
  @include flex(flex-start, center, row, nowrap);
}

.AppOrderingPay__button {
  margin-right: 15px;
}

.AppOrderingPay__backState {
  @include flex(center, center, row, nowrap);
  @include text($h16, 300, $light-yellow, $f-montserrat);
  cursor: pointer
}

.AppOrderingPay__backState-text {
  margin-left: 5px;
}
</style>
