<template>
  <div class="AppOrderingAudit">
    <div class="AppOrderingAudit__inner">
      <ul class="AppOrderingAudit__items">
        <li
            v-for="itemInCart in getCartItems"
            v-if="$route.params.type === 'item' || $route.params.type === 'all'"
            :key="itemInCart['title_' + selectedLang]"
            class="AppOrderingAudit__item"
        >
          <span class="itemInCart__amount">x{{ itemInCart.amount }}</span>
          <span class="itemInCart__title">{{ itemInCart['title_' + selectedLang] }}</span>
        </li>
        <li
            v-for="itemInCart in getCartPies"
            v-if="$route.params.type === 'pie' || $route.params.type === 'all'"
            :key="itemInCart['title_' + selectedLang]"
            class="AppOrderingAudit__item"
        >
          <span class="itemInCart__amount">x{{ itemInCart.amount }}</span>
          <span class="itemInCart__title">{{ itemInCart['title_' + selectedLang] }}</span>
        </li>
      </ul>
      <div class="AppOrderingAudit__comment" v-if="$route.params.type === 'item' || $route.params.type === 'all'">
        {{ getData.cartNoteItems }}
      </div>
      <div class="AppOrderingAudit__comment" v-if="$route.params.type === 'pie' || $route.params.type === 'all'">
        {{ getData.cartNotePies }}
      </div>
      <div class="AppOrderingAudit__price">
        Всего:<span class="AppOrderingAudit__cost"
      >{{ getCartTotalPrice }}
          <span class="AppOrderingAudit__currency">{{wallet['text_' + selectedLang]}}</span></span
      >
      </div>
      <div v-if="!getData.pickup" class="AppOrderingAudit__address">
        {{ getData.address ? text1['text_' + selectedLang] + getData.address + ", " : "" }}
        {{ getData.later ? getData.date + text2['text_' + selectedLang] + getData.time + "." : text3['text_' + selectedLang] }}
      </div>
      <div class="AppOrderingAudit__buttons">
        <button
            @click.prevent="[changeActiveStep({ key: 'isPay' }), scrollToTop()]"
            class="AppOrderingAudit__button"
        >
          <app-button class="button--fill">{{text4['text_' + selectedLang]}}</app-button>
        </button>
        <div
            class="AppOrderingAudit__backState"
            @click="[changeActiveStep({ key: 'isTill' }), scrollToTop()]"
        >
          <icon-arrow class="iconArrow--left iconArrow--light-yellow"/>
          <span class="AppOrderingAudit__backState-text">{{text5['text_' + selectedLang]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapGetters as mapRootGetters} from "vuex";
import iconArrow from "@/modules/app/components/svg/iconArrow.vue";

import {AppButton} from "@/modules/app/components";

const {mapActions, mapGetters} = createNamespacedHelpers("ordering");

export default {
  name: "AppOrderingAudit.vue",

  components: {AppButton, iconArrow},

  data() {
    return {
      wallet: {
        text_ru: 'грн',
        text_ua: 'грн',
        text_pl: 'hrywien',
        text_en: 'UAH',
      },
      text1: {
        text_ru: 'Будет доставлено на адрес ',
        text_ua: 'Буде доставленона адресу ',
        text_pl: 'Zostanie dostarczony na adres ',
        text_en: 'Will be delivered to the address ',
      },
      text2: {
        text_ru: ' в ',
        text_ua: ' о ',
        text_pl: ' o ',
        text_en: ' at ',
      },
      text3: {
        text_ru: 'через 60мин.',
        text_ua: 'через 60хв.',
        text_pl: 'w 60 minut.',
        text_en: 'in 60 minutes.',
      },
      text4: {
        text_ru: 'Заплатить картой',
        text_ua: 'Оплатити карткою',
        text_pl: 'Zapłać kartą',
        text_en: 'Pay by card',
      },
      text5: {
        text_ru: 'Вернуться',
        text_ua: 'Повернутися',
        text_pl: 'Powrót',
        text_en: 'Return',
      },
    };
  },

  methods: {
    ...mapActions(["changeData", "changeActiveStep"]),

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },

  computed: {
    ...mapGetters(["getData"]),

    ...mapRootGetters(["getCartItems", "getCartTotalPrice", "getCartPies", "getCartAll"]),

    ...mapRootGetters('lang', ['selectedLang']),
  },
};
</script>

<style lang="scss">
.AppOrderingAudit {

  &__items {
    margin-bottom: 14px;
  }

  &__comment {
    @include text($h16, 300, $grey, $f-montserrat);
    margin-left: 35px;
    max-width: 255px;
    line-height: 21px;
    margin-bottom: 14px;
  }

  &__price {
    @include text($h16, 300, $grey, $f-montserrat);
    margin-bottom: 28px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 55px;
    }
  }

  &__cost {
    @include text($h16, 300, $yellow, $f-montserrat);
    margin-left: 13px;
  }

  &__currency {
    font-size: 10px;
  }

  &__address {
    @include text($h15, 400, $light-yellow, $f-montserrat);
    max-width: 415px;
    margin-bottom: 66px;
    @include media-d-m($screen-netbook) {
      margin-bottom: 34px;
    }
  }

  &__buttons {
    @include flex(flex-start, center, row, nowrap);
  }

  &__button {
    margin-right: 15px;
  }

  &__backState {
    @include flex(center, center, row, nowrap);
    @include text($h16, 300, $light-yellow, $f-montserrat);
    cursor: pointer;
  }

  &__backState-text {
    margin-left: 5px;
  }
}

.itemInCart {
  :not(:last-of-type) {
    margin-bottom: 4px;
  }

  &__amount {
    @include text($h15, 400, $grey, $f-montserrat);
    display: inline-flex;
    min-width: 17px;
    margin-right: 18px;
  }

  &__title {
    @include text($h16, 300, $light-yellow, $f-montserrat);
  }
}
</style>
