<template>
  <div class="AppCheckbox">
    <input
      class="AppCheckbox__checkbox"
      type="checkbox"
      :id="nameInput"
      :name="nameInput"
      v-model="valueChecked"
      @input="pushvModel(nameInput, !valueChecked)"
      :class="getClass"
    />
    <label :for="nameInput">{{ label }}</label>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');

export default {
  name: 'AppCheckbox',
  components: {},
  props: {
    nameInput: String,
    label: String,
    vModel: String,
    getClass: String,
  },

  data() {
    return {
      valueChecked: false,
      valueInput: false
    };
  },

  mounted() {
    if (this.getvData(this.nameInput)) {
      this.valueChecked = true
    }
  },

  methods: {
    ...mapActions(['changeData']),
    ...mapGetters(['getData']),
    pushvModel(name, value) {
      this.changeData({ key: name, value });
    },
    getvData(state) {
      return this.getData()[state];
    }
  },
};
</script>

<style lang="scss">
.AppCheckbox__checkbox {
  &.light-yellow {
    & + label {
      @include text($h16, 300, $light-yellow, $f-montserrat);
    }
  }

  /* для элемента input c type="checkbox" */
  & {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  /* для элемента label, связанного с AppCheckbox__checkbox */
  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    @include text($h16, 300, $grey, $f-montserrat);
    cursor: pointer;
    @include transition(color);
  }

  /* создание в label псевдоэлемента before со следующими стилями */
  & + label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $grey;
    margin-right: 0.5em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include transition(background-image);
  }

  /* стили при наведении курсора на checkbox */
  // &:not(:disabled):not(:checked) + label:hover::before {
  // }

  /* стили для активного чекбокса (при нажатии на него) */
  // &:not(:disabled):active + label::before {
  // }

  /* стили для чекбокса, находящегося в фокусе */
  // &:focus + label::before {
  // }

  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  // &:focus:not(:checked) + label::before {
  // }

  /* стили для чекбокса, находящегося в состоянии checked */
  &:checked + label::before {
    background-image: url('../../../assets/images/checked/checked.svg');
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  &:disabled + label::before {
    opacity: 0.5;
  }
}
</style>
